<template>
  <CContainer fluid>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CRow class="justify-content-between px-3">
              <b>Booking</b>
              <div class="d-flex">
                <CButton
                  @click="assignOtherSP.isShowModal = true"
                  size="sm"
                  color="danger"
                  class="mr-2 font-weight-bold"
                  :disabled="booking.status != 'ongoing'"
                  >Assign to other SP</CButton
                >
                <CButton
                  @click="onApproveLateCancellation"
                  size="sm"
                  color="primary"
                  class="mr-2 font-weight-bold"
                  :disabled="
                    booking.late_cancellation_status &&
                    booking.late_cancellation_status != 'pending'
                  "
                  >Approve Late Cancellation</CButton
                >
                <CButton
                  @click="onRejectLateCancellation"
                  size="sm"
                  color="danger"
                  class="mr-2 font-weight-bold"
                  :disabled="
                    booking.late_cancellation_status &&
                    booking.late_cancellation_status != 'pending'
                  "
                  >Reject Late Cancellation</CButton
                >
                <CButton
                  @click="changeStatus.isShowModal = true"
                  size="sm"
                  color="warning"
                  class="mr-2 font-weight-bold"
                  >Change Status</CButton
                >
                <CButton
                  size="sm"
                  color="secondary"
                  class="mr-2 font-weight-bold"
                  @click="onFetchBookingHistory"
                  >Audit trail</CButton
                >
                <CButton
                  size="sm"
                  color="light"
                  class="mr-2 font-weight-bold"
                  @click="onFetchBookingQuoteHistory"
                  >Quote audit trail</CButton
                >
                <CButton
                  size="sm"
                  color="dark"
                  class="mr-2 font-weight-bold"
                  @click="onFetchNoteInternal"
                  >Internal Notes</CButton
                >
                <CButton
                  size="sm"
                  color="primary"
                  class="mr-2 font-weight-bold"
                  @click="onShowTaskQueue"
                  >Schedule</CButton
                >
                <CButton
                  size="sm"
                  color="secondary"
                  class="mr-0 font-weight-bold"
                  @click="showNotificaitonList = true"
                  >Notification</CButton
                >
              </div>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <dt class="col-sm-2">ID</dt>
              <dd class="col-sm-10">{{ booking.id }}</dd>

              <dt class="col-sm-2">BookingID</dt>
              <dd class="col-sm-10">
                {{ booking.booking_no ? booking.booking_no : '-' }}
              </dd>

              <dt class="col-sm-2">ConsumerID</dt>
              <dd class="col-sm-10">
                <ConsumerHoverComponent
                  :id="booking.consumer_id ? booking.consumer_id : 0"
                />
              </dd>
              <dt class="col-sm-2">SP ID</dt>
              <dd class="col-sm-10">
                <span v-if="booking.provider_id">
                  <ProviderHoverComponent
                    :id="booking.provider_id ? booking.provider_id : 0"
                  />
                </span>
                <span v-else>-</span>
              </dd>

              <dt class="col-sm-2">Category</dt>
              <dd class="col-sm-10">
                {{ listCategories[booking.category_id] || '-' }}
              </dd>
              <dt class="col-sm-2">SubCategory</dt>
              <dd class="col-sm-10">
                {{ listSubCategories[booking.subcategory_id] || '-' }}
              </dd>

              <dt class="col-sm-2">Service List</dt>
              <dd class="col-sm-10">
                {{ booking.service_list || '-' }}
              </dd>

              <dt class="col-sm-2">Address</dt>
              <dd class="col-sm-10">{{ booking.address }}</dd>
              <dt class="col-sm-2">UnitNumber</dt>
              <dd class="col-sm-10">{{ booking.unit_number }}</dd>
              <dt class="col-sm-2">PostalCode</dt>
              <dd class="col-sm-10">{{ booking.postal_code }}</dd>
              <dt class="col-sm-2">BookingType</dt>
              <dd class="col-sm-10">{{ booking.booking_type }}</dd>
              <dt class="col-sm-2">PriceType</dt>
              <dd class="col-sm-10">{{ booking.price_type }}</dd>
              <dt class="col-sm-2">QuoteType</dt>
              <dd class="col-sm-10">
                {{ booking.quote_type ? booking.quote_type : '-' }}
              </dd>

              <dt class="col-sm-2">Price</dt>
              <dd class="col-sm-10">
                {{ booking.total_price | currency100 }}
              </dd>
              <dt class="col-sm-2">Additional fee</dt>
              <dd class="col-sm-10">
                {{ booking.additional_fee | currency100 }}
              </dd>
              <dt class="col-sm-2">Lumpsum</dt>
              <dd class="col-sm-10">{{ booking.lumpsum | currency100 }}</dd>

              <dt class="col-sm-2">Status</dt>
              <dd class="col-sm-10">
                <CBadge :color="getBadge(booking.status)">
                  {{ booking.status }}
                </CBadge>
              </dd>

              <dt class="col-sm-2">Late cancellation status</dt>
              <dd class="col-sm-10">
                {{ booking.late_cancellation_status || '-' }}
              </dd>

              <dt class="col-sm-2">Appointment</dt>
              <dd class="col-sm-10">
                <span v-if="booking.appointment">
                  <b>{{ booking.appointment | moment(datetimeFormat) }}</b>
                </span>
                <span v-else>-</span>
              </dd>

              <dt class="col-sm-2">PaymentMethod</dt>
              <dd class="col-sm-10">
                {{ booking.payment_method }}
                <CLink
                  :href="
                    env.stripeURL +
                    `/search?query=is:customer ${booking.payment_method_id}`
                  "
                  target="_blank"
                >
                  {{ booking.payment_method_id }}
                </CLink>
              </dd>

              <dt class="col-sm-2">Photos</dt>
              <dd class="col-sm-10 d-flex">
                <template v-for="attachment in booking.attachments">
                  <div :key="attachment.id" style="width: 100px" class="mr-2">
                    <expandable-image
                      :src="attachment.url"
                      :close-on-background-click="true"
                      class="mb-2"
                      shape="rounded"
                    />
                  </div>
                </template>
              </dd>

              <dt class="col-sm-2">Additional information</dt>
              <dd class="col-sm-10">
                {{ booking.note }}
              </dd>

              <dt class="col-sm-2">UpdatedDate</dt>
              <dd class="col-sm-10">
                {{ booking.updated_at | moment(datetimeFormat) }}
              </dd>

              <dt class="col-sm-2">CreatedDate</dt>
              <dd class="col-sm-10">
                {{ booking.created_at | moment(datetimeFormat) }}
              </dd>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <InvoiceListComponent
          title="Invoices"
          :items="invoices"
          :fields="invoicesFields"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <JobListComponent
          title="Jobs of Booking"
          :items="jobs"
          :fields="jobsFields"
          :booking_status="booking.status ? booking.status : ''"
          @changeToCompleted="onChangeJobToCompleted"
          @onChangeToOngoing="onChangeJobToOngoing"
        />
      </CCol>
    </CRow>
    <ViewNoteInternalComponent
      title="Notes"
      :isShow="showNoteInternalList"
      :noteData="noteData"
      @close="showNoteInternalList = false"
    />
    <ViewBookingAuditComponent
      :title="'Booking Audit trail #' + id"
      type="booking"
      :isShow="showBookingHistory"
      :historyData="histories"
      @close="showBookingHistory = false"
    />
    <ViewBookingAuditComponent
      :title="'Quote Audit trail #' + id"
      type="quote"
      :isShow="showBookingQuoteHistory"
      :historyData="quote_histories"
      @close="showBookingQuoteHistory = false"
    />

    <CModal
      title="Change status of Booking"
      :show.sync="changeStatus.isShowModal"
      size="lg"
      :centered="true"
      :closeOnBackdrop="false"
    >
      <CForm>
        <CCol sm="10">
          <h5 style="color: red">
            Noted: Only Booking with the status Pending/Ongoing can be changed
            status
          </h5>

          <CSelect
            label="Status"
            :value.sync="changeStatus.actionSelected"
            :options="changeStatus.options"
          />
        </CCol>
      </CForm>
      <template #footer>
        <CButton @click="changeStatus.isShowModal = false" color="secondary"
          >Cancel</CButton
        >
        <CButton @click="onChangeStatusBooking" color="primary">
          <CSpinner :hidden="!changeStatus.loading" size="sm" />

          Save</CButton
        >
      </template>
    </CModal>

    <vue-confirm-dialog></vue-confirm-dialog>

    <ScheduleListModel
      :showModal="showScheduleList"
      :bookingId="id ? id : ''"
      :datas="tasks"
      @close-modal="showScheduleList = false"
    />

    <BookingNotificationModel
      :showModal="showNotificaitonList"
      :bookingId="id ? id : ''"
      @close-modal="showNotificaitonList = false"
    />

    <AssignOtherProviderModel
      :showModal="assignOtherSP.isShowModal"
      :bookingId="id ? id : ''"
      :providerId="booking.provider_id ? booking.provider_id : 0"
      @close-modal="assignOtherSP.isShowModal = false"
      @onSave="onAssignOtherProvider"
    />

    <Loading :active.sync="isLoading" :is-full-page="isFullPage"></Loading>
  </CContainer>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ACTION_FETCH_BOOKING,
  ACTION_FETCH_BOOKING_JOBS,
  ACTION_FETCH_INVOICES,
  ACTION_FETCH_BOOKING_HISTORY,
  ACTION_FETCH_BOOKING_QUOTE_HISTORY,
  ACTION_FETCH_CATEGORIES,
} from '@/store/actions.type';
import { BookingBadge } from '@/utils/utils';
import { Common } from '@/constants';
import env from '@/constants/env';
import InvoiceListComponent from '@/components/InvoiceListComponent';
import JobListComponent from '@/components/JobListComponent';
import AdminProxy from '@/proxies/admin.proxy';
import BookingProxy from '@/proxies/booking.proxy';
import ViewNoteInternalComponent from '@/components/ViewNoteInternalComponent';
import ViewBookingAuditComponent from '@/components/ViewBookingAuditComponent';
import ScheduleListModel from '@/components/modals/ScheduleListModel';
import BookingNotificationModel from '@/components/modals/BookingNotificationModel';
import AssignOtherProviderModel from '@/components/modals/AssignOtherProviderModel';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ConsumerHoverComponent from '@/components/ConsumerHoverComponent.vue';
import ProviderHoverComponent from '@/components/ProviderHoverComponent.vue';

export default {
  name: 'Booking',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes('bookings');
    });
  },
  components: {
    Loading,
    InvoiceListComponent,
    JobListComponent,
    ViewNoteInternalComponent,
    ViewBookingAuditComponent,
    ScheduleListModel,
    BookingNotificationModel,
    AssignOtherProviderModel,
    ConsumerHoverComponent,
    ProviderHoverComponent,
  },
  data() {
    return {
      env,
      datetimeFormat: Common.datetimeFormat,
      id: undefined,
      isLoading: false,
      isFullPage: true,
      invoicesFields: [
        {
          key: 'invoice_no',
          label: 'InvoiceNo',
        },
        {
          key: 'job_id',
          label: 'JobID',
        },
        {
          key: 'provider_id',
          label: 'ProviderID',
        },
        {
          key: 'issued_by',
          label: 'IssuedBy',
        },
        {
          key: 'pdf_file',
          label: 'PDF',
        },
        {
          key: 'created_at',
          label: 'CreatedDate',
        },
      ],
      jobsFields: [
        {
          key: 'id',
          label: 'JobID',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'amount',
          label: 'Amount',
        },
        {
          key: 'ongoing_date',
          label: 'Appointment',
        },
        {
          key: 'completed_date',
          label: 'Completed date',
        },
        {
          key: 'rating',
          label: 'Rating',
        },
        {
          key: 'review',
          label: 'Review',
        },
        {
          key: 'updated_at',
          label: 'UpdatedAt',
        },
        // {
        //   key: 'created_at',
        //   label: 'CreatedAt',
        // },
        {
          key: 'action',
          label: 'Action',
        },
      ],
      noteData: {
        notes: [],
        id: undefined,
        type: 'booking',
      },
      showNoteInternalList: false,
      showScheduleList: false,
      showBookingHistory: false,
      showBookingQuoteHistory: false,
      changeStatus: {
        loading: false,
        isShowModal: false,
        actionSelected: '',
        options: [],
      },
      tasks: [],
      showNotificaitonList: false,
      assignOtherSP: {
        loading: false,
        isShowModal: false,
        actionSelected: '',
        options: [],
      },
    };
  },
  mounted() {
    let bookingId = this.$route.params.id;

    this.id = bookingId;
    this.noteData.id = bookingId;

    this.fetchData(bookingId);
  },
  computed: {
    ...mapGetters([
      'booking',
      'invoices',
      'jobs',
      'histories',
      'quote_histories',
      'categories',
      'listCategories',
      'listSubCategories',
    ]),
  },
  methods: {
    getBadge: (status) => BookingBadge(status),
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: '/bookings' });
    },
    fetchData(bookingId) {
      this.$store.dispatch(ACTION_FETCH_BOOKING, bookingId).then(() => {
        if (this.booking.status == 'pending') {
          this.changeStatus.options = [
            { value: 'expried_booking', label: 'Expried Booking' },
          ];
          this.changeStatus.actionSelected = 'expried_booking';
        } else if (this.booking.status == 'completed') {
          this.changeStatus.options = [
            // { value: 'ongoing', label: 'Ongoing' },
            // { value: 'cancelled_by_comenow', label: 'Cancelled by Comenow' },
          ];
          this.changeStatus.actionSelected = 'ongoing';
        } else if (this.booking.status == 'ongoing') {
          this.changeStatus.options = [
            // { value: 'completed', label: 'Completed' },
            { value: 'cancelled_by_sp', label: 'Cancelled by SP' },
            { value: 'cancelled_by_consumer', label: 'Cancelled by Consumer' },
            {
              value: 'late_or_onsite_cancelled_by_consumer',
              label: 'Onsite/LateCancelled by Consumer',
            },
            { value: 'cancelled_by_comenow', label: 'Cancelled by Comenow' },
          ];
          this.changeStatus.actionSelected = 'cancelled_by_sp';
        } else {
          this.changeStatus.options = [];
          this.changeStatus.actionSelected = '';
        }
      });

      this.$store.dispatch(ACTION_FETCH_INVOICES, { booking_id: bookingId });

      this.$store.dispatch(ACTION_FETCH_BOOKING_JOBS, bookingId);

      if (this.categories.length <= 0) {
        this.$store.dispatch(ACTION_FETCH_CATEGORIES, {});
      }
    },
    onFetchNoteInternal() {
      let query = { type: this.noteData.type, id: this.id };
      AdminProxy.fetchNoteInternal(query).then(({ data }) => {
        this.showNoteInternalList = true;
        this.noteData.notes = data;
      });
    },
    onFetchBookingHistory() {
      let query = { booking_id: this.id };
      this.$store.dispatch(ACTION_FETCH_BOOKING_HISTORY, query).then(() => {
        this.showBookingHistory = true;
      });
    },
    onFetchBookingQuoteHistory() {
      let booking_id = this.id;
      this.$store
        .dispatch(ACTION_FETCH_BOOKING_QUOTE_HISTORY, booking_id)
        .then(() => {
          this.showBookingQuoteHistory = true;
        });
    },
    onChangeStatusBooking() {
      let action = this.changeStatus.actionSelected;
      if (action) {
        this.changeStatus.loading = true;
        BookingProxy.changeStatusWithAction(this.id, action, {}).then(() => {
          this.changeStatus.isShowModal = false;
          this.changeStatus.loading = false;

          this.fetchData(this.id);
        });
      }
    },
    onChangeJobToCompleted(job) {
      this.$confirm({
        message: `Change JobID: ${job.id} to completed. Are you sure?`,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            BookingProxy.changeJobStatusWithAction(
              job.id,
              'completed',
              {}
            ).then(() => {
              this.fetchData(this.id);
              setTimeout(() => {
                this.$toast.warning(`Change status is successfully`, {
                  duration: 3000,
                });
              }, 1000);
            });
          }
        },
      });
    },
    onChangeJobToOngoing(job) {
      this.$confirm({
        message: `Change JobID: ${job.id} to ongoing. Are you sure?`,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            BookingProxy.changeJobStatusWithAction(job.id, 'ongoing', {}).then(
              () => {
                this.fetchData(this.id);
                setTimeout(() => {
                  this.$toast.success(`Change status is successfully`, {
                    duration: 3000,
                  });
                }, 1000);
              }
            );
          }
        },
      });
    },
    onApproveLateCancellation() {
      this.$confirm({
        title: 'Are you sure?',
        message: `Do you want approve a booking late cancellation?`,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            BookingProxy.adminApproveLateCancellation(this.id).then(() => {
              this.fetchData(this.id);
              this.isLoading = false;
              setTimeout(() => {
                this.$toast.success(`Update is successfully`, {
                  duration: 3000,
                });
              }, 300);
            });
          }
        },
      });
    },
    onRejectLateCancellation() {
      this.$confirm({
        title: 'Are you sure?',
        message: `Do you want reject a booking late cancellation?`,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            BookingProxy.adminRejectLateCancellation(this.id).then(() => {
              this.fetchData(this.id);
              this.isLoading = false;
              setTimeout(() => {
                this.$toast.success(`Update is successfully`, {
                  duration: 3000,
                });
              }, 300);
            });
          }
        },
      });
    },
    onShowTaskQueue() {
      BookingProxy.adminGetTaskQueueBooking(this.id).then(({ data }) => {
        this.tasks = data;
        this.showScheduleList = true;
      });
    },
    onAssignOtherProvider(data) {
      console.log('🚀 ~ onAssignOtherProvider:', data);
      let { selected, providerId } = data;
      if (selected == providerId) {
        this.$toast.success(`Can't assign the same Provider`, {
          duration: 3000,
        });
        return;
      }

      this.isLoading = true;
      BookingProxy.adminAssignToProvider(this.id, {
        provider_id: selected,
      }).then(() => {
        this.fetchData(this.id);
        this.isLoading = false;
        setTimeout(() => {
          this.$toast.success(`Assign to new a Provider is successfully`, {
            duration: 3000,
          });
        }, 300);
      });
    },
  },
};
</script>
